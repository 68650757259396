import React from "react";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const environmentalProtection = () => {
  const lang = "de";

  return (
    <Layout
      seoTitle="Umweltschutz"
      lang={lang}
      translationPL="/ochrona-srodowiska/"
      translationEN="/en/environmental-protection/"
    >
      <SmallBanner title="Umweltschutz" lang={lang} />
      <section className="text-section text-section--first">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
              Umweltfreundliches 
                <br />
                Unternehmen:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
              PalettenWerk setzt Maßnahmen um, die zu einer vernünftigen und sicheren Einwirkung auf die Umwelt führen. Die Umweltpolitik von PalattenWerk umfasst einen effizienten und sparsamen Einsatz von Rohstoffen und Energie sowie die Minimierung der negativen Einwirkungen von Produktionsprozessen auf die Umwelt.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
              Rationeller
                <br />
                Umgang mit Rohstoffen:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
              Die Holzpaletten von PalettenWerk sind ein komplett umweltfreundliches, langlebiges und wiederverwendbares Produkt. Aus Paletten, die nicht repariert werden können, werden andere Produkte wie Pellets und Sägemehl hergestellt. Das für die Herstellung von Paletten verwendete Holz stammt ausschließlich aus Waldrestholz und Industrieholz.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
              Einsparung 
                <br />und Rückgewinnung von Energie:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
              Das Unternehmen PalettenWerk steht für zahlreiche technologische Innovationen im Bereich der Energieeinsparung und -rückgewinnung. Im Produktionsprozess werden Sägemehl- und Baumrindenöfen verwendet, und die bei der Verbrennung und Palettentrocknung entstehende Wärme wird teilweise zurückgewonnen und wiederverwertet.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="text-section text-section--last">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-5 col-xxl-5">
              <h2 className="small color--main mb-60">
                Ethische 
                <br />
                Rohstoffbeschaffung:
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-7 col-xl-8 col-xxl-8 offset-lg-5 offset-xl-4 offset-xxl-4">
              <p className="text-section__content">
              Die PalettenWerk-Produkte werden ausschließlich aus Rohstoffen hergestellt, die aus nachhaltig bewirtschafteten Wäldern stammen. Sie werden in Übereinstimmung mit umweltfreundlichen, sozialen und ethischen Standards hergestellt. Diese Standards werden durch das 2015 erhaltene PEFC-Zertifikat bestätigt.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default environmentalProtection;
